exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ar-about-js": () => import("./../../../src/pages/ar/about.js" /* webpackChunkName: "component---src-pages-ar-about-js" */),
  "component---src-pages-ar-blog-detail-js": () => import("./../../../src/pages/ar/blog-detail.js" /* webpackChunkName: "component---src-pages-ar-blog-detail-js" */),
  "component---src-pages-ar-blog-js": () => import("./../../../src/pages/ar/blog.js" /* webpackChunkName: "component---src-pages-ar-blog-js" */),
  "component---src-pages-ar-careers-js": () => import("./../../../src/pages/ar/careers.js" /* webpackChunkName: "component---src-pages-ar-careers-js" */),
  "component---src-pages-ar-clients-js": () => import("./../../../src/pages/ar/clients.js" /* webpackChunkName: "component---src-pages-ar-clients-js" */),
  "component---src-pages-ar-contact-us-js": () => import("./../../../src/pages/ar/contact-us.js" /* webpackChunkName: "component---src-pages-ar-contact-us-js" */),
  "component---src-pages-ar-index-js": () => import("./../../../src/pages/ar/index.js" /* webpackChunkName: "component---src-pages-ar-index-js" */),
  "component---src-pages-ar-industries-js": () => import("./../../../src/pages/ar/industries.js" /* webpackChunkName: "component---src-pages-ar-industries-js" */),
  "component---src-pages-ar-projects-detail-js": () => import("./../../../src/pages/ar/projects-detail.js" /* webpackChunkName: "component---src-pages-ar-projects-detail-js" */),
  "component---src-pages-ar-projects-js": () => import("./../../../src/pages/ar/projects.js" /* webpackChunkName: "component---src-pages-ar-projects-js" */),
  "component---src-pages-ar-services-ai-software-development-services-js": () => import("./../../../src/pages/ar/services/ai-software-development-services.js" /* webpackChunkName: "component---src-pages-ar-services-ai-software-development-services-js" */),
  "component---src-pages-ar-services-best-mobile-app-developers-js": () => import("./../../../src/pages/ar/services/best-mobile-app-developers.js" /* webpackChunkName: "component---src-pages-ar-services-best-mobile-app-developers-js" */),
  "component---src-pages-ar-services-digital-marketing-strategy-consultancy-js": () => import("./../../../src/pages/ar/services/digital-marketing-strategy-consultancy.js" /* webpackChunkName: "component---src-pages-ar-services-digital-marketing-strategy-consultancy-js" */),
  "component---src-pages-ar-services-js": () => import("./../../../src/pages/ar/services.js" /* webpackChunkName: "component---src-pages-ar-services-js" */),
  "component---src-pages-ar-services-professional-seo-services-js": () => import("./../../../src/pages/ar/services/professional-seo-services.js" /* webpackChunkName: "component---src-pages-ar-services-professional-seo-services-js" */),
  "component---src-pages-ar-services-seo-responsive-web-design-js": () => import("./../../../src/pages/ar/services/seo-responsive-web-design.js" /* webpackChunkName: "component---src-pages-ar-services-seo-responsive-web-design-js" */),
  "component---src-pages-ar-services-social-media-management-services-js": () => import("./../../../src/pages/ar/services/social-media-management-services.js" /* webpackChunkName: "component---src-pages-ar-services-social-media-management-services-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-blog-detail-js": () => import("./../../../src/pages/en/blog-detail.js" /* webpackChunkName: "component---src-pages-en-blog-detail-js" */),
  "component---src-pages-en-blog-js": () => import("./../../../src/pages/en/blog.js" /* webpackChunkName: "component---src-pages-en-blog-js" */),
  "component---src-pages-en-careers-js": () => import("./../../../src/pages/en/careers.js" /* webpackChunkName: "component---src-pages-en-careers-js" */),
  "component---src-pages-en-clients-js": () => import("./../../../src/pages/en/clients.js" /* webpackChunkName: "component---src-pages-en-clients-js" */),
  "component---src-pages-en-contact-us-js": () => import("./../../../src/pages/en/contact-us.js" /* webpackChunkName: "component---src-pages-en-contact-us-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-industries-js": () => import("./../../../src/pages/en/industries.js" /* webpackChunkName: "component---src-pages-en-industries-js" */),
  "component---src-pages-en-projects-detail-js": () => import("./../../../src/pages/en/projects-detail.js" /* webpackChunkName: "component---src-pages-en-projects-detail-js" */),
  "component---src-pages-en-projects-js": () => import("./../../../src/pages/en/projects.js" /* webpackChunkName: "component---src-pages-en-projects-js" */),
  "component---src-pages-en-services-ai-software-development-services-js": () => import("./../../../src/pages/en/services/ai-software-development-services.js" /* webpackChunkName: "component---src-pages-en-services-ai-software-development-services-js" */),
  "component---src-pages-en-services-best-mobile-app-developers-js": () => import("./../../../src/pages/en/services/best-mobile-app-developers.js" /* webpackChunkName: "component---src-pages-en-services-best-mobile-app-developers-js" */),
  "component---src-pages-en-services-digital-marketing-strategy-consultancy-js": () => import("./../../../src/pages/en/services/digital-marketing-strategy-consultancy.js" /* webpackChunkName: "component---src-pages-en-services-digital-marketing-strategy-consultancy-js" */),
  "component---src-pages-en-services-js": () => import("./../../../src/pages/en/services.js" /* webpackChunkName: "component---src-pages-en-services-js" */),
  "component---src-pages-en-services-professional-seo-services-js": () => import("./../../../src/pages/en/services/professional-seo-services.js" /* webpackChunkName: "component---src-pages-en-services-professional-seo-services-js" */),
  "component---src-pages-en-services-seo-responsive-web-design-js": () => import("./../../../src/pages/en/services/seo-responsive-web-design.js" /* webpackChunkName: "component---src-pages-en-services-seo-responsive-web-design-js" */),
  "component---src-pages-en-services-social-media-management-services-js": () => import("./../../../src/pages/en/services/social-media-management-services.js" /* webpackChunkName: "component---src-pages-en-services-social-media-management-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

